import React, { useMemo } from 'react';

import EditIcon from '../assets/edit.svg?react';
import PreviewIcon from '../assets/preview.svg?react';
import {
  renderAddressColumn,
  renderGameEventDateColumn,
  renderGameLeagueColumn,
  renderGameNumberColumn,
  renderParticipantsColumn,
  renderRefereesColumn,
  renderSecretaryColumn,
  renderTeamsColumn,
} from '../components/game/game-columns';
import type { Column } from '../components/table/table';
import TableActions from '../components/table/table-actions';
import { createColumns } from '../components/table/utils';
import { useAppConfigQuery } from '../services/app';
import type { GameInList } from '../services/game';
import { useTranslation } from '../translations/useTranslation';

type ColumnConfig = {
  displayRefs?: boolean;
  displaySecretary?: boolean;
  displayPersons?: boolean;
  actionCol?: Column<GameInList>;
};

const useGameListColumns = (props?: ColumnConfig) => {
  const { t } = useTranslation();
  const appConfigQuery = useAppConfigQuery();

  const displayRefs = props?.displayRefs !== undefined ? props.displayRefs : true;
  const displaySecretary = props?.displaySecretary !== undefined ? props.displaySecretary : true;
  const displayPersons = props?.displayPersons !== undefined ? props.displayPersons : true;

  const actionCol =
    props?.actionCol !== undefined
      ? props.actionCol
      : {
          Header: '',
          size: 1,
          Cell: (item: GameInList) => (
            <TableActions
              actions={[
                {
                  label: <PreviewIcon />,
                  type: 'link',
                  route: `/games/${item.id}`,
                  permissions: ['user'],
                  tooltip: t('msg_tooltip_preview'),
                },
                {
                  label: <EditIcon />,
                  type: 'link',
                  tooltip: t('msg_tooltip_edit'),
                  route: `/games/edit/${item.id}`,
                  permissions: ['admin'],
                },
              ]}
            />
          ),
        };

  return useMemo(() => {
    return createColumns<GameInList>([
      appConfigQuery.data?.features.game.gameNumber ? renderGameNumberColumn(t) : undefined,
      renderGameLeagueColumn(t),
      renderGameEventDateColumn(t),
      renderTeamsColumn(t),
      renderAddressColumn(t),
      displayRefs ? renderRefereesColumn(t) : undefined,
      displaySecretary && appConfigQuery.data?.features.appointments.secretary ? renderSecretaryColumn(t) : undefined,
      displayPersons &&
      (appConfigQuery.data?.features.appointments.commissioner || appConfigQuery.data?.features.appointments.delegate)
        ? renderParticipantsColumn(t)
        : undefined,
      actionCol,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, appConfigQuery]);
};

export default useGameListColumns;
