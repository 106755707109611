import type React from 'react';

import { cn } from '../../utils/styles';

type CenteredModalWrapperProps = {
  children: React.ReactNode;
  onClose?: () => void;
  title?: string;
  limitWidth?: boolean;
};

const CenteredModalWrapper = ({ children, onClose, title, limitWidth = true }: CenteredModalWrapperProps) => {
  return (
    <div className={cn('relative w-full p-4 md:max-h-screen', limitWidth && 'max-w-2xl')}>
      <div className="relative rounded-lg bg-white shadow">
        <div className="flex items-center justify-between rounded-t border-b bg-boxdark p-4">
          {title && <h3 className="text-xl font-medium text-white">{title}</h3>}

          <button
            type="button"
            className="ms-auto inline-flex size-8 items-center justify-center rounded-lg bg-transparent text-sm text-white hover:text-bodydark"
            data-modal-hide="default-modal"
            onClick={onClose}
          >
            <svg
              className="size-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="max-h-[90vh] overflow-scroll">
          <div className="p-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CenteredModalWrapper;
